import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import {
  Critica,
  Loja,
  Produto,
  SelectType,
  ParcelaFinanceiro,
  NfsReferenciadas,
  ParametrosLoja,
  LogProps,
  SubTotais,
  ProdutoProps,
  NfSelecionada,
} from './protocols';
import { schema } from './validations/FormDataValidation';
import { schemaFormFinanceiro } from '~/pages/DevolucaoESaidaNFDeFornecedor/validations/FormFinanceiroValidation';
import useAuth from '~/hooks/useAuth';
import moment from 'moment';
import api from '~/services/api';
import { schemaModalItens } from './validations/ModalItensValidation';
import { nfItensValidation } from './validations/NFItensValidation';
import {
  CalculaCamposCalculados,
  procedureCalculaSubtotal,
} from './functions/Procedures';
import { moneyFormat } from '~/utils/functions';
import { MasterDetailProps } from '~/utils/masterDetail';
import clearForm from './screens/Financeiro/FormParcelas/clearForm.json';

type DevolucaoSaidaNFEFornContextData = {
  codLoja: number;
  loja: Loja;
  checkIgnoraBaseDeCalculo: (flg_ignora_bc: boolean) => void;
  changeLoja: (data: Loja) => void;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control<FieldValues, any>;
  clearErrors: UseFormClearErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
  parceiro: SelectType;
  setParceiro: React.Dispatch<React.SetStateAction<SelectType>>;
  perfil: SelectType;
  setPerfil: React.Dispatch<React.SetStateAction<SelectType>>;
  produtos: Produto[];
  setProdutos: (value: React.SetStateAction<Produto[]>) => void;
  setCodLoja: (value: React.SetStateAction<number>) => void;
  setCriticas: (value: React.SetStateAction<Critica[]>) => void;
  criticas: Critica[];
  condicoesPagamento: SelectType[];
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  currentTab: string;
  setParcelasFinanceiro: React.Dispatch<
    React.SetStateAction<ParcelaFinanceiro[]>
  >;
  parcelasFinanceiro: ParcelaFinanceiro[];
  setParcelaSelecionada: React.Dispatch<
    React.SetStateAction<ParcelaFinanceiro>
  >;
  parcelaSelecionada: ParcelaFinanceiro;
  formFinanceiro: {
    register: UseFormRegister<FieldValues>;
    handleSubmit: UseFormHandleSubmit<FieldValues>;
    control: Control<FieldValues, any>;
    reset: UseFormReset<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    formState: FormState<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    trigger: UseFormTrigger<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
    setError: UseFormSetError<FieldValues>;
  };
  masterDetail: MasterDetailProps[];
  setMasterDetail: (masterDetail: MasterDetailProps[]) => void;
  uuidParcela: { uuid: string; cod_parcela: number | undefined } | undefined;
  setUuidParcela: (
    item: { uuid: string; cod_parcela: number | undefined } | undefined,
  ) => void;
  formModal: {
    registerModal: UseFormRegister<FieldValues>;
    handleSubmitModal: UseFormHandleSubmit<FieldValues>;
    controlModal: Control<FieldValues, any>;
    resetModal: UseFormReset<FieldValues>;
    setValueModal: UseFormSetValue<FieldValues>;
    formStateModal: FormState<FieldValues>;
    getValuesModal: UseFormGetValues<FieldValues>;
    setErrorModal: UseFormSetError<FieldValues>;
    clearErrorModal: UseFormClearErrors<FieldValues>;
    setFocusModal: UseFormSetFocus<FieldValues>;
  };
  formTabelaItens: {
    registerTabelaItens: UseFormRegister<FieldValues>;
    handleSubmitTabelaItens: UseFormHandleSubmit<FieldValues>;
    controlTabelaItens: Control<FieldValues, any>;
    resetTabelaItens: UseFormReset<FieldValues>;
    setValueTabelaItens: UseFormSetValue<FieldValues>;
    formStateTabelaItens: FormState<FieldValues>;
    getValuesTabelaItens: UseFormGetValues<FieldValues>;
    setErrorTabelaItens: any;
    clearErrorTabelaItens: UseFormClearErrors<FieldValues>;
    setFocusTabelaItens: UseFormSetFocus<FieldValues>;
    watchTabelaItens: UseFormWatch<FieldValues>;
    unregisterTabelaItens: UseFormUnregister<FieldValues>;
  };
  setNfsReferenciadas: React.Dispatch<React.SetStateAction<NfsReferenciadas[]>>;
  nfsReferenciadas: NfsReferenciadas[];
  setParametrosLoja: React.Dispatch<React.SetStateAction<ParametrosLoja>>;
  parametrosLoja: ParametrosLoja;
  setLogs: React.Dispatch<React.SetStateAction<LogProps[]>>;
  logs: LogProps[];
  setProdutoSelecionado: React.Dispatch<React.SetStateAction<Produto>>;
  produtoSelecionado: Produto;
  subTotais: SubTotais;
  setSubTotais: React.Dispatch<React.SetStateAction<SubTotais>>;
  resetFormData: () => void;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  produtosModal: ProdutoProps[];
  setProdutosModal: React.Dispatch<React.SetStateAction<ProdutoProps[]>>;
  nfs: any[];
  setNfs: React.Dispatch<React.SetStateAction<any[]>>;
  nfSelecionada: NfSelecionada;
  setNFSelecionada: React.Dispatch<React.SetStateAction<NfSelecionada>>;
  isFormItendEditing: boolean;
  setIsFormItendEditing: React.Dispatch<React.SetStateAction<boolean>>;
  buscarProduto: (val: any) => void;
  recalculaTotais: () => void;
  resetItens: boolean;
  setResetItens: React.Dispatch<React.SetStateAction<boolean>>;
  setNfCancelada: React.Dispatch<React.SetStateAction<boolean>>;
  nfCancelada: boolean;
};

export const DevolucaoSaidaNFEFornContext = createContext(
  {} as DevolucaoSaidaNFEFornContextData,
);

interface DevolucaoSaidaNFEFornContextProviderProps {
  children: ReactNode;
}

export function DevolucaoSaidaNFEFornContextProvider({
  children,
}: DevolucaoSaidaNFEFornContextProviderProps): JSX.Element {
  const { user } = useAuth();
  const [isUpdate, setIsUpdate] = useState(false);
  const [nfsReferenciadas, setNfsReferenciadas] = useState<NfsReferenciadas[]>(
    [],
  );
  const [parametrosLoja, setParametrosLoja] = useState<ParametrosLoja>(
    {} as ParametrosLoja,
  );
  const [currentTab, setCurrentTab] = useState<string>('nfe');
  const [criticas, setCriticas] = useState<Critica[]>([]);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState<any>(
    {} as Produto,
  );
  const [parcelasFinanceiro, setParcelasFinanceiro] = useState<
    ParcelaFinanceiro[]
  >([]);
  const [parcelaSelecionada, setParcelaSelecionada] =
    useState<ParcelaFinanceiro>({} as ParcelaFinanceiro);
  const [condicoesPagamento, setCondicoesPagamento] = useState<SelectType[]>(
    [],
  );
  const [subTotais, setSubTotais] = useState<SubTotais>({
    totalProdutos: 0,
    totalST: 0,
    fcpSt: 0,
    guiaSt: 0,
    ipi: 0,
    icmsOp: 0,
    frete: 0,
    totalNf: 0,
    bcIcmsOp: 0,
  });
  const [logs, setLogs] = useState<LogProps[]>([]);
  const [loja, setLoja] = useState({} as Loja);
  const [codLoja, setCodLoja] = useState<number>(0);
  const [parceiro, setParceiro] = useState<SelectType>({} as SelectType);
  const [perfil, setPerfil] = useState<any>({} as SelectType);
  const [nfCancelada, setNfCancelada] = useState<boolean>(false);

  /**
   * States do modal de seleção de produtos
   */
  const [produtosModal, setProdutosModal] = useState<ProdutoProps[]>([]);
  const [nfs, setNfs] = useState<any[]>([]);
  const [nfSelecionada, setNFSelecionada] = useState<NfSelecionada>({
    cod_seq_nf: 0,
    num_nf: 0,
  });
  const [isFormItendEditing, setIsFormItendEditing] = useState(false);
  /**
   * O State abaixo serve para informar o useEffect do CustomInputMoney
   * (src\pages\DevolucaoESaidaNFDeFornecedor\screens\Nfe\Itens\TableItens\TableItens\components\CustomInputMoney.tsx)
   * Que ele deve preencher novamente os inputs contidos na tabela de itens pois algum item foi removido.
   */
  const [resetItens, setResetItens] = useState(true);

  /**
   * Inicio aplicacao master detail
   */

  const masterDetailDefault = [
    {
      obj_name: 'parcelas',
      pk_fields: ['cod_parcela'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);
  const [uuidParcela, setUuidParcela] = useState<{
    uuid: string;
    cod_parcela: number | undefined;
  }>();

  /**
   * Form Capa / transporte
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
    watch,
    setFocus,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    setValue: setValueModal,
    control: controlModal,
    reset: resetModal,
    formState: formStateModal,
    getValues: getValuesModal,
    setError: setErrorModal,
    clearErrors: clearErrorModal,
    setFocus: setFocusModal,
  } = useForm({
    resolver: yupResolver(schemaModalItens),
    reValidateMode: 'onBlur',
  });

  const {
    register: registerTabelaItens,
    handleSubmit: handleSubmitTabelaItens,
    setValue: setValueTabelaItens,
    control: controlTabelaItens,
    reset: resetTabelaItens,
    formState: formStateTabelaItens,
    getValues: getValuesTabelaItens,
    setError: setErrorTabelaItens,
    clearErrors: clearErrorTabelaItens,
    setFocus: setFocusTabelaItens,
    watch: watchTabelaItens,
    unregister: unregisterTabelaItens,
  } = useForm({
    // resolver: yupResolver(schemaTabelaItensItens),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setCurrentTab('nfe');
  }, [formState.errors]);
  /**
   * Form Financeiro
   */
  const {
    register: registerFormFinanceiro,
    handleSubmit: handleSubmitFormFinanceiro,
    control: controlFormFinanceiro,
    reset: resetFormFinanceiro,
    setValue: setValueFormFinanceiro,
    getValues: getValuesFormFinanceiro,
    formState: formStateFinanceiro,
    watch: watchFormFinanceiro,
    trigger: triggerFormFinanceiro,
    clearErrors: clearErrorsFormFinanceiro,
    setError: setErrorFormFinanceiro,
  } = useForm({
    resolver: yupResolver(schemaFormFinanceiro),
  });

  /**
   * Funções
   */
  function changeLoja(data: any) {
    setLoja(data);
  }

  const resetLoja = useCallback(async () => {
    if (user.loja) {
      const { data } = await api.get(`/lojas/${user.loja}`);
      if (data.success) {
        setValue('loja', data.data.cod_loja);
        setCodLoja(data.data.cod_loja);
        setLoja({
          uf: data.data.des_uf,
          cod_loja: data.data.cod_loja,
          tipo_regime: data.data.tipo_regime,
        });
      }
    }
  }, [setValue, user.loja]);

  const resetFormData = useCallback(() => {
    setLoja({} as Loja);

    setCodLoja(0);
    setParceiro({} as SelectType);
    setPerfil({} as SelectType);
    setProdutos([]);
    setCriticas([]);
    setCondicoesPagamento([]);
    setParcelasFinanceiro([]);
    setNfsReferenciadas([]);
    setLogs([]);
    setProdutosModal([]);
    setNfs([]);
    setNFSelecionada({
      cod_seq_nf: 0,
      num_nf: 0,
    });
    setParametrosLoja({} as ParametrosLoja);
    setParcelaSelecionada({} as ParcelaFinanceiro);
    setProdutoSelecionado({} as Produto);
    setSubTotais({
      totalProdutos: 0,
      totalST: 0,
      fcpSt: 0,
      guiaSt: 0,
      ipi: 0,
      icmsOp: 0,
      frete: 0,
      totalNf: 0,
      bcIcmsOp: 0,
    });
    setCurrentTab('nfe');
    resetFormFinanceiro(clearForm);
    reset();
    resetLoja();
    setValue('cod_pessoa', '');
    setValue('cod_perfil', '');
    setValue('cod_serie', '');
    setValue('cod_especie', { value: 33, label: 'NFE' });
    setValue('dta_emissao', '');
    setValue('dta_entrada', '');
    setValue('des_obs_livre', '');
    setValue('qtd_peso_liq', '');
    setValue('qtd_peso_bruto', '');
    setMasterDetail(JSON.parse(JSON.stringify(masterDetailDefault)));
    setNfCancelada(false);
  }, [reset, resetFormFinanceiro, resetLoja, setValue]);

  useEffect(() => {
    resetLoja();
  }, [user, resetLoja]);

  const checkIgnoraBaseDeCalculo = useCallback(
    (flg_ignora_bc: boolean) => {
      setValue('flg_ignora_bc', flg_ignora_bc);
      // setProdutoSelecionado({
      //   ...produtoSelecionado,
      //   flg_nao_calc_bc_icms: flg_ignora_bc,
      // });
      if (flg_ignora_bc === false) {
        clearErrors();
      }
    },
    [clearErrors, produtoSelecionado, setProdutoSelecionado, setValue],
  );

  const buscarProduto = useCallback(
    (val: any) => {
      setValueModal('produtos', {
        label: `${val[0].cod_produto} - ${val[0].des_produto}`,
        value: val[0].cod_produto,
      });
    },
    [setValueModal],
  );

  const recalculaTotais = useCallback(() => {
    const fornecedor = getValues('cod_pessoa');
    const isOperacaoInterestadual = loja.uf !== fornecedor.des_uf;
    const VTotLiqItensSubst = 0;
    const VTotFimItensICMSA = 0;
    const QTotICMSAntecItens = 0;
    const pICMSRetido = 0;
    const parametroLoja = {
      flg_ignora_st_cf_custo: parametrosLoja.flg_ignora_st_cf_custo,
    };

    const newProdutos = [];
    for (let i = 0; i < produtos.length; i++) {
      const produto = produtos[i];
      const item = CalculaCamposCalculados(
        isOperacaoInterestadual,
        VTotLiqItensSubst,
        VTotFimItensICMSA,
        QTotICMSAntecItens,
        pICMSRetido,
        produto,
        0,
        fornecedor,
        perfil,
        parametroLoja,
        { per_desp_op: produto.per_desp_op },
        true,
      );
      newProdutos.push(item);
    }

    setProdutos(newProdutos);

    const {
      val_total_ipi,
      val_total_bc,
      val_total_icms,
      val_total_bc_st,
      val_total_icms_st,
      val_total_venda_varejo,
      val_total_st_fcp,
      val_total_nf,
      dta_entrada,
    } = getValues();

    // Validar ICMS?
    const formDataFormated = {
      val_total_ipi,
      val_total_bc,
      val_total_icms,
      val_total_bc_st,
      val_total_icms_st,
      val_total_venda_varejo,
      val_total_st_fcp,
      val_total_nf,
      dta_entrada,
    };

    nfItensValidation(
      formDataFormated,
      newProdutos,
      perfil.tipo_operacao,
      setValue,
    );

    const subtotais = procedureCalculaSubtotal(newProdutos);
    setSubTotais(subtotais);
    // icmsValidation(
    //   formDataFormated,
    //   newProdutos,
    //   perfil.tipo_operacao,
    //   setValue,
    // );
  }, [
    getValues,
    loja.uf,
    parametrosLoja?.flg_ignora_st_cf_custo,
    perfil,
    produtos,
    setValue,
  ]);

  return (
    <DevolucaoSaidaNFEFornContext.Provider
      value={{
        checkIgnoraBaseDeCalculo,
        loja,
        changeLoja,
        codLoja,
        setCodLoja,
        register,
        formState,
        control,
        setValue,
        getValues,
        setError,
        handleSubmit,
        clearErrors,
        watch,
        setFocus,
        parceiro,
        setParceiro,
        perfil,
        setPerfil,
        setProdutos,
        produtos,
        criticas,
        setCriticas,
        condicoesPagamento,
        setCurrentTab,
        currentTab,
        setParcelasFinanceiro,
        parcelasFinanceiro,
        setParcelaSelecionada,
        parcelaSelecionada,
        formFinanceiro: {
          register: registerFormFinanceiro,
          handleSubmit: handleSubmitFormFinanceiro,
          control: controlFormFinanceiro,
          reset: resetFormFinanceiro,
          setValue: setValueFormFinanceiro,
          getValues: getValuesFormFinanceiro,
          formState: formStateFinanceiro,
          watch: watchFormFinanceiro,
          trigger: triggerFormFinanceiro,
          clearErrors: clearErrorsFormFinanceiro,
          setError: setErrorFormFinanceiro,
        },
        formModal: {
          registerModal,
          handleSubmitModal,
          controlModal,
          resetModal,
          setValueModal,
          formStateModal,
          getValuesModal,
          setErrorModal,
          clearErrorModal,
          setFocusModal,
        },
        formTabelaItens: {
          registerTabelaItens,
          handleSubmitTabelaItens,
          controlTabelaItens,
          resetTabelaItens,
          setValueTabelaItens,
          formStateTabelaItens,
          getValuesTabelaItens,
          setErrorTabelaItens,
          clearErrorTabelaItens,
          setFocusTabelaItens,
          watchTabelaItens,
          unregisterTabelaItens,
        },
        masterDetail,
        setMasterDetail,
        uuidParcela,
        setUuidParcela,
        setNfsReferenciadas,
        nfsReferenciadas,
        setParametrosLoja,
        parametrosLoja,
        setLogs,
        logs,
        setProdutoSelecionado,
        produtoSelecionado,
        subTotais,
        setSubTotais,
        resetFormData,
        isUpdate,
        setIsUpdate,
        produtosModal,
        setProdutosModal,
        nfs,
        setNfs,
        nfSelecionada,
        setNFSelecionada,
        isFormItendEditing,
        setIsFormItendEditing,
        buscarProduto,
        recalculaTotais,
        resetItens,
        setResetItens,
        nfCancelada,
        setNfCancelada,
      }}
    >
      {children}
    </DevolucaoSaidaNFEFornContext.Provider>
  );
}

export const useDevolucaoSaidaNFEForn =
  (): DevolucaoSaidaNFEFornContextData => {
    return useContext(DevolucaoSaidaNFEFornContext);
  };
