import { GridRowParams } from '@material-ui/data-grid';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import api from '~/services/api';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';

import { formatPerfil } from '../EntradaNFE/functions/formatacao/formatPerfil';
import { useDevolucaoSaidaNFEForn } from './DevolucaoSaidaNFEFornContext';
import { TruncaFrac } from './functions/Auxiliares';
import {
  procedureCalculaPISCofins,
  procedureCalculaSubtotal,
} from './functions/Procedures';
import { LogProps } from './protocols';
import { Critica } from './screens/Critica';
import { Financeiro } from './screens/Financeiro';
import { Log } from './screens/Log';
import { Nfe } from './screens/Nfe';
import { Produtor } from './screens/Produtor';
import { Container } from './styles';
import { financeiroValidation } from './validations/FinanceiroValidation';
import { icmsValidation } from './validations/IcmsValidation';
import { nfItensValidation } from './validations/NFItensValidation';
import { Context } from '~/components/Nfe/TransmissaoNFe/Transmissao';
import { TransmitirNFe } from '~/components/TransmitirNFe';
import { loadingContext } from '~/context/loading';
import { isBefore, parseISO } from 'date-fns';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

interface AditionalValidations {
  description: string;
}

export const DevolucaoSaidaNFEFornContent: React.FC = () => {
  const { transmitirNFe } = useContext(Context);
  const {
    criticas,
    setCriticas,
    handleSubmit,
    produtos,
    currentTab,
    setCurrentTab,
    parametrosLoja,
    setProdutos,
    nfsReferenciadas,
    setNfsReferenciadas,
    parcelasFinanceiro,
    setParcelasFinanceiro,
    setLogs,
    resetFormData,
    isUpdate,
    setIsUpdate,
    setError,
    setCodLoja,
    setValue,
    getValues,
    setSubTotais,
    masterDetail,
    nfCancelada,
    setNfCancelada,
  } = useDevolucaoSaidaNFEForn();
  const { setLoading } = useContext(loadingContext);
  const [showSearch, setShowSearch] = useState(true);
  const [nfManutencao, setNfManutencao] = useState(false);

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      // Aba NFE
      setCodLoja(row.cod_loja);
      setValue('loja', row.cod_loja);

      setValue('cod_pessoa', {
        value: row.cod_pessoa,
        label: row.nome_pessoa,
      });

      const perfilDocumento = await api.get(`/perfil-nf-outras-operacoes/`, {
        params: {
          cod_loja: row.cod_loja,
          cod_perfil: row.cod_perfil,
        },
      });
      setNfManutencao(row.flg_manutencao);
      // eslint-disable-next-line no-shadow
      const perfil: any = formatPerfil(perfilDocumento.data.data);
      setValue('cod_perfil', perfil);

      if (perfil.tipo_emissao === 1) {
        setNfCancelada(row.flg_cancelada);
      }
      perfil.tipo_emissao === 1
        ? setValue('cod_serie', {
            value: row.num_serie_nf,
            label: `${row.num_serie_nf}`,
          })
        : setValue('cod_serie', row.num_serie_nf);

      setValue('num_nf', row.num_nf);
      setValue('cod_seq_nf', row.cod_seq_nf);
      setValue('dta_emissao', String(row.dta_emissao).substring(0, 10));
      setValue('dta_entrada', String(row.dta_entrada).substring(0, 10));
      setValue('val_total_nf', formatCurrencyAsText(row.val_total_nf));
      setValue('val_total_bc', formatCurrencyAsText(row.val_total_bc));
      setValue('val_total_icms', formatCurrencyAsText(row.val_total_icms));
      setValue('val_total_bc_st', formatCurrencyAsText(row.val_total_bc_st));
      setValue(
        'val_total_icms_st',
        formatCurrencyAsText(row.val_total_icms_st),
      );
      setValue('val_total_st_fcp', formatCurrencyAsText(row.val_total_st_fcp));
      setValue(
        'val_total_venda_varejo',
        formatCurrencyAsText(row.val_total_venda_varejo),
      );
      setValue('val_total_ipi', formatCurrencyAsText(row.val_total_ipi));
      // Aba transporte
      setValue('val_seguro', row.val_seguro);
      setValue('qtd_embalagem', row.qtd_embalagem);
      setValue('qtd_peso_liq', formatCurrencyAsText(row.qtd_peso_liq));
      setValue('qtd_peso_bruto', formatCurrencyAsText(row.qtd_peso_bruto));
      setValue('des_marca_veiculo', row.des_marca_veiculo);
      setValue('des_placa_veiculo', row.des_placa_veiculo);
      setValue('des_obs_livre', row.des_obs_fiscal);
      // busca itens nfe
      {
        const res = await api.get(
          `/nf-devolucao-saida/${row.cod_seq_nf}/itens`,
        );
        const { data, success, message } = res.data;

        if (!success) {
          throw new Error(message);
        }

        setProdutos(data);
        const subtotais = procedureCalculaSubtotal(data);
        setSubTotais(subtotais);
      }
      // busca parcelas financeiro nfe
      {
        const res = await api.get(`/nf/${row.cod_seq_nf}/financeiro`);
        const { data, success, message } = res.data;

        if (!success) {
          throw new Error(message);
        }
        const formatterData = data.map((item: any) => {
          return {
            id: nanoid(),
            ...item,
          };
        });
        setParcelasFinanceiro(formatterData);
      }

      // itens-referenciados
      const itensReferenciados = await api.get(
        `/nf-devolucao-saida/itens-referenciados/${row.cod_seq_nf}`,
      );

      setNfsReferenciadas(itensReferenciados.data.data);
      let logs = [];
      if (row.logs.length > 0 && row.logs[0] !== null) {
        const formatedLogs = row.logs.map((log: LogProps) => {
          return {
            id: nanoid(),
            ...log,
            dta_cadastro: log.dta_cadastro
              ? moment(createDateWithoutTimezone(log.dta_cadastro)).format(
                  'DD-MM-YYYY',
                )
              : '',
          };
        });
        logs = formatedLogs;
      }
      setLogs(logs);
      setIsUpdate(true);
      setShowSearch(false);
    },
    [
      setCodLoja,
      setIsUpdate,
      setLogs,
      setNfsReferenciadas,
      setParcelasFinanceiro,
      setProdutos,
      setSubTotais,
      setValue,
    ],
  );

  const formatData = useCallback(
    (formData: FieldValues) => {
      /**
       * Formata Dados para insert na tab NF_FORNECEDOR_ITEM
       */
      const val_total_nf = getValues('val_total_nf');
      const val_total_bc = getValues('val_total_bc');
      const val_total_icms = getValues('val_total_icms');
      const val_total_bc_st = getValues('val_total_bc_st');
      const val_total_icms_st = getValues('val_total_icms_st');
      const val_total_st_fcp = getValues('val_total_st_fcp');
      const val_total_venda_varejo = getValues('val_total_venda_varejo');
      const val_total_ipi = getValues('val_total_ipi');

      let val_total_icms_fcp = 0;
      const ArrayCodSeqNfRef: any[] = [];
      const itens: any = produtos.map((produto) => {
        val_total_icms_fcp += produto.val_fcp_icms;
        const valTotalSemIpiFreteSt =
          transformAsCurrency(val_total_nf) -
          (produto.val_tot_ipi + produto.val_tot_icms_st + produto.val_fcp_st);
        const val_isento_ipi =
          produto.val_tot_ipi !== 0 ? 0 : valTotalSemIpiFreteSt;
        const {
          val_base_pis,
          val_base_cofins,
          val_pis,
          val_cofins,
          val_isento_pis_cof,
        } = procedureCalculaPISCofins(
          false, // flg_nao_pis_cofins do ncm
          produto.flg_nao_pis_cofins,
          formData.cod_pessoa.flg_nao_transf_pis_cofins,
          parametrosLoja.flg_st_bc_pis_cof,
          parametrosLoja.flg_ipi_bc_pis_cof,
          produto.tipo_nao_pis_cofins,
          transformAsCurrency(formData.val_total_nf),
          produto.val_tot_icms_st + produto.val_bc_st,
          produto.val_tot_ipi,
          0, // per_pis_item
          0, // per_confins_item
        );

        const ref = ArrayCodSeqNfRef.find(
          (item) => item.cod_seq_nf_ref === produto.cod_seq_nf,
        );

        if (!ref) {
          ArrayCodSeqNfRef.push({ cod_seq_nf_ref: produto.cod_seq_nf });
        }

        return {
          // identificação do item
          num_item: produto.num_item,
          cod_produto: produto.cod_produto,
          cfop: produto.cfop,
          cod_seq_nf_ref: produto.cod_seq_nf,
          cod_seq_nf_item_origem: produto.cod_seq_nf_item,
          cod_sit_tributaria: produto.cod_sit_tributaria,
          cod_id_ctb: produto.cod_id_ctb,
          des_unidade: produto.des_unidade,
          // tributação
          cod_ncm: produto.cod_ncm,
          cod_tributacao: produto.cod_tributacao,
          val_bc_icms: transformAsCurrency(produto.val_bc_icms || 0),
          val_icms: transformAsCurrency(produto.val_icms || 0), // Crédito_ICMS
          val_outros: transformAsCurrency(produto.val_outros_icms || 0),
          val_isento: transformAsCurrency(produto.val_isento_icms || 0),
          val_bc_st: transformAsCurrency(produto.val_bc_st || 0),
          val_bc_st_entrada: transformAsCurrency(
            produto.val_bc_st_entrada || 0,
          ),
          val_bc_st_guia: transformAsCurrency(
            produto.val_bc_st_guia_saida || 0,
          ), // Saída
          val_bc_st_guia_entrada: transformAsCurrency(
            produto.val_bc_st_guia_entrada,
          ),
          val_icms_st: transformAsCurrency(produto.val_icms_st || 0),
          val_icms_st_cred: transformAsCurrency(produto.val_icms_st_cred || 0),
          val_icms_st_guia: transformAsCurrency(produto.val_icms_st_guia || 0),
          // quantidade e valores
          qtd_embalagem: Number(produto.qtd_embalagem),
          qtd_entrada: Number(produto.qtd_devolucao),
          qtd_total: Number(produto.qtd_total),
          val_peso: Number(produto.val_peso),
          val_tabela: transformAsCurrency(produto.val_tabela || 0), // valor unitário
          val_tabela_liq: transformAsCurrency(produto.val_tabela_liq || 0),
          val_tabela_final: transformAsCurrency(produto.val_tabela_final || 0),
          // val_total: TruncaFrac(
          //   transformAsCurrency(produto.val_tabela_final || 0),
          //   2,
          // ), // val_tot_nf
          val_total: transformAsCurrency(produto.val_tabela_final || 0), // val_tot_nf
          val_desconto: transformAsCurrency(produto.val_tot_desconto || 0),
          val_despesa_acessoria: transformAsCurrency(
            produto.val_despesa_acessoria || 0,
          ),
          val_ipi: transformAsCurrency(produto.val_ipi || 0),
          // frete
          val_frete: transformAsCurrency(produto.val_frete || 0),
          val_frete_dest: transformAsCurrency(produto.val_frete_dest || 0),
          val_bc_st_frete_dest: transformAsCurrency(
            produto.val_bc_st_frete_dest || 0,
          ),
          val_icms_st_frete_dest: transformAsCurrency(
            produto.val_icms_st_frete_dest || 0,
          ),
          // pis/cofins
          flg_nao_pis_cofins: produto.flg_nao_pis_cofins,
          tipo_nao_pis_cofins: produto.tipo_nao_pis_cofins,
          // fcp
          per_fcp_st: Number(produto.per_fcp_st),
          val_fcp_st: transformAsCurrency(produto.val_fcp_st || 0),
          per_fcp_icms: Number(produto.per_fcp_icms),
          val_fcp_icms: transformAsCurrency(produto.val_fcp_icms || 0),
          // venda varejo
          val_venda_varejo: transformAsCurrency(produto.val_venda_varejo || 0),
          val_venda_varejo_guia: transformAsCurrency(
            produto.val_venda_varejo_guia || 0,
          ),
          // IVA
          val_pauta_iva: transformAsCurrency(produto.val_pauta_iva || 0),
          // Outros
          val_pauta_ipv: transformAsCurrency(produto.val_pauta_ipv || 0),
          flg_bonificado: produto.flg_bonificado,
          flg_guia: produto.flg_guia,
          flg_nao_calc_bc_icms: produto.flg_nao_calc_bc_icms,
          // nf_Item abaixo
          // cod_seq_nf: 0, // Gerado no insert da tabela NF
          des_produto: produto.des_produto,
          cod_gtin: produto.cod_gtin_principal,
          num_ncm: produto.num_ncm,
          tipo_tributacao: Number(produto.tipo_tributacao_ent_nf), // tipo_tributacao é tipo_tributacao_ent_nf ou tipo_tributacao_sai_prod
          per_aliq_icms: produto.per_aliq_icms,
          per_red_bc_icms: Number(produto.per_red_bc_icms),
          val_base_ipi: transformAsCurrency(produto.val_base_ipi || 0),
          val_isento_ipi: transformAsCurrency(val_isento_ipi || 0), // Existe na nf_item (adicionar ao select?) OU manter cálculo da análise
          val_outros_ipi: transformAsCurrency(produto.val_outros_ipi || 0),
          val_pis: transformAsCurrency(val_pis || 0), // Existe na nf_fornecedor_item e na nf_item (adicionar ao select?)
          val_isento_pis: transformAsCurrency(val_isento_pis_cof || 0), // Existe na nf_item (adicionar ao select?)
          val_cofins: transformAsCurrency(val_cofins || 0), // Existe na nf_fornecedor_item e na nf_item (adicionar ao select?)
          val_isento_cofins: transformAsCurrency(val_isento_pis_cof || 0), // Existe na nf_item (adicionar ao select?)
          per_iva: transformAsCurrency(produto.per_iva || 0),
          val_base_pis: transformAsCurrency(val_base_pis || 0), // Existe na nf_item (adicionar ao select?)
          val_base_cofins: transformAsCurrency(val_base_cofins || 0), // Existe na nf_item (adicionar ao select?)
          cod_item_embalagem: Number(produto.cod_item_embalagem),
          val_nf: produto.val_nf,
          val_produto: produto.val_produto,
          val_custo_scred: produto.val_custo_scred,
          val_custo_cheio: produto.val_custo_cheio,
          val_custo_medio: produto.val_custo_medio,
          val_custo_pmz: produto.val_custo_pmz,
          val_custo_rep: produto.val_custo_rep,
          val_custo_sicms: produto.val_custo_sicms,
          val_custo_tabela: produto.val_custo_tabela,
        };
      });

      /**
       * Formata Dados para insert na tab NF
       */
      const dataToInsertInNF = {
        // cod_seq_nf: 0, Provavel que seja gerado automaticamente
        cod_pessoa: formData.cod_pessoa.value, // Acredito que seja o código pessoa do fornecedor selecionado
        num_nf: Number(formData.num_nf),
        num_serie_nf: Number(formData.cod_serie.value),
        cod_perfil: formData.cod_perfil.value,
        tipo_operacao: formData.cod_perfil.tipo_operacao,
        tipo_nf: formData.cod_perfil.tipo_nf,
        cod_loja: formData.loja,
        num_chave_acesso: '', // Valor fixo segundo a análise
        des_especie: 'NFE',
        cod_especie: 33,
        cod_doc_fiscal: '55',
        num_modelo_fiscal: '',
        des_natureza: formData.cod_perfil.des_perfil.substring(0, 35),
        val_total_nf: transformAsCurrency(val_total_nf),
        dta_emissao: formData.dta_emissao,
        dta_entrada: formData.dta_entrada,
        flg_cancelada: false,
        num_cpf_cnpj: formData.cod_pessoa.num_cpf_cnpj, // Acredito que seja do fornecedor selecionado
        num_rg_ie: formData.cod_pessoa.num_rg_ie,
        des_razao_social: formData.cod_pessoa.nome_pessoa,
        tipo_frete: 9, // Segundo a análise deve-se manter o valor fixo 9
        tipo_emitente: formData.cod_perfil.tipo_emissao === 0 ? 1 : 0,
        des_obs_livre: formData.des_obs_livre,
        des_obs_fiscal: formData.des_obs_livre,
        flg_optante_simples: formData.cod_pessoa.flg_optante_simples ?? false,
        flg_gera_financeiro: formData.cod_perfil.flg_gera_financeiro ?? false,
      };

      /**
       * Formata Dados para insert na tab NF_FORNECEDOR
       */

      const dataToInsertInNFFornecedor = {
        // cod_seq_nf: 0, // Gerada durante o insert na tabela NF
        cod_pessoa: formData.cod_pessoa.value,
        cod_loja: formData.loja,

        tipo_nf: formData.cod_perfil.tipo_nf, // Efetuo o cálculo da análise ou utilizo o tipo_nf contido no perfil selecionado?
        num_nf: formData.num_nf,
        num_serie_nf: formData.cod_serie.value,
        des_especie: formData.cod_especie.des_especie,
        cod_perfil: formData.cod_perfil.value,
        des_natureza: formData.cod_perfil.des_perfil.substring(0, 35),
        dta_emissao: formData.dta_emissao,
        dta_entrada: formData.dta_entrada,
        des_obs_fiscal: formData.des_obs_livre,
        flg_gera_estoque: true, // Valor fixo segundo a análise
        num_chave_acesso: '', // Valor fixo segundo a análise
        val_funrural: 0, // Não criado na tabela ainda
        val_total_nf: transformAsCurrency(val_total_nf || 0),
        val_total_bc: transformAsCurrency(val_total_bc || 0),
        val_total_icms: transformAsCurrency(val_total_icms || 0),
        val_total_bc_st: transformAsCurrency(val_total_bc_st || 0),
        val_total_icms_st: transformAsCurrency(val_total_icms_st || 0),
        val_total_st_fcp: transformAsCurrency(val_total_st_fcp || 0),
        val_total_venda_varejo: transformAsCurrency(val_total_venda_varejo),
        val_total_ipi: transformAsCurrency(val_total_ipi || 0),
        val_total_icms_fcp: transformAsCurrency(val_total_icms_fcp || 0),
        val_frete: 0, // Valor fixo segundo a análise
        val_despesa_acessoria: 0, // Valor fixo segundo a análise
        val_acrescimo: 0, // Valor fixo segundo a análise
        val_desconto: 0, // Valor fixo segundo a análise
        val_indenizacao: 0, // Valor fixo segundo a análise
        val_seguro: transformAsCurrency(formData.val_seguro || 0),
        qtd_embalagem: Number(formData.qtd_embalagem || 1),
        des_marca_veiculo: formData.des_marca_veiculo,
        des_placa_veiculo: formData.des_placa_veiculo,
        qtd_peso_liq: transformAsCurrency(formData.qtd_peso_liq || 0),
        qtd_peso_bruto: transformAsCurrency(formData.qtd_peso_bruto || 0),
        val_desc_financeiro: transformAsCurrency(
          formData.val_desc_financeiro || 0,
        ),
        val_enc_financeiro: transformAsCurrency(
          formData.val_enc_financeiro || 0,
        ),
      };
      /**
       * Formata Dados para insert na tab NF_FORNECEDOR
       */
      const dataToInsertInFinanceiro = {
        cod_categoria: formData.cod_perfil.cod_categoria,
        cod_cc: formData.cod_perfil.cod_cc,
      };

      return {
        ...dataToInsertInNF,
        ...dataToInsertInNFFornecedor,
        ...dataToInsertInFinanceiro,
        masterDetail,
        itensRef: ArrayCodSeqNfRef,
        itens,
      };
    },
    [
      parametrosLoja?.flg_ipi_bc_pis_cof,
      parametrosLoja?.flg_st_bc_pis_cof,
      parcelasFinanceiro,
      produtos,
      getValues,
    ],
  );

  function CreateTable(itens: AditionalValidations[]): void {
    const AlertTableHeader = '<tr><th>Descrição</th></tr>';
    const AlertTableBody = itens.map((item: AditionalValidations): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.description}${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 10px'>
            </p>
            `;
    MySwal.fire({
      icon: 'warning',
      width: 800,
      title: 'Regularize os itens apontados para prosseguir.',
      html: String(AlertTable),
    });
  }

  const aditionalValidations = useCallback((): AditionalValidations[] => {
    const invalid: AditionalValidations[] = [];
    if (nfsReferenciadas.length <= 0) {
      invalid.push({
        description: 'É necessário referenciar ao menos uma NF.',
      });
    }
    if (produtos.length <= 0) {
      invalid.push({
        description: 'É necessário adicionar ao menos um item na tabela.',
      });
    }

    return invalid;
  }, [nfsReferenciadas.length, produtos.length]);

  const onSave = handleSubmit(async (formData) => {
    const parsedDtaEntrada = parseISO(formData.dta_entrada);
    const parsedDtaEmissao = parseISO(formData.dta_emissao);

    if (isBefore(parsedDtaEntrada, parsedDtaEmissao)) {
      toast.warn('Data Entrada não deve ser anterior a Data Emissão.');
      return;
    }

    setLoading(true);
    const nfValidations = aditionalValidations();
    if (
      nfValidations.length > 0 ||
      getValues('cod_serie')?.value === undefined
    ) {
      if (getValues('cod_serie')?.value === undefined) {
        setError('cod_serie', { type: 'focus' }, { shouldFocus: true });
      }
      if (nfValidations.length > 0) CreateTable(nfValidations);
      setLoading(false);
      return;
    }

    const validacaoEndereco = await api.get(
      `/nf-outras-operacoes/validacao-endereco/${formData.cod_pessoa.value}`,
    );

    if (!validacaoEndereco.data.success) {
      toast.warning(validacaoEndereco.data.message);
      setCurrentTab('nfe');
      setLoading(false);
      return;
    }

    /**
     * Valida Valor Tabela dos itens
     */
    const invalidItems = Object.values(produtos).some(
      (item) => Number(item.val_tabela) <= 0,
    );
    if (invalidItems) {
      toast.warn(
        'Valor Tabela dos itens deve ser informado e deve ser maior que zero.',
      );
      setLoading(false);
      return;
    }

    let formDataFormated = {
      val_total_ipi: formData.val_total_ipi,
      val_total_bc: formData.val_total_bc,
      val_total_icms: formData.val_total_icms,
      val_total_bc_st: formData.val_total_bc_st,
      val_total_icms_st: formData.val_total_icms_st,
      val_total_venda_varejo: formData.val_total_venda_varejo,
      val_total_st_fcp: formData.val_total_st_fcp,
      val_total_nf: formData.val_total_nf,
      dta_entrada: formData.dta_entrada,
    };

    const nfItensValidations = nfItensValidation(
      formDataFormated,
      produtos,
      formData.cod_perfil.tipo_operacao,
      setValue,
    );
    const icmsValidations = icmsValidation(
      formDataFormated,
      produtos,
      formData.cod_perfil.tipo_operacao,
      setValue,
    );

    let financeiroValidations = [];
    let validations = [];
    const totalNf = getValues('val_total_nf');
    if (
      transformAsCurrency(formDataFormated.val_total_nf).toFixed(2) !==
      transformAsCurrency(totalNf).toFixed(2)
    ) {
      formDataFormated = {
        ...formDataFormated,
        val_total_nf: totalNf,
      };
    }
    if (
      formData.cod_perfil.flg_gera_financeiro ||
      parcelasFinanceiro.length > 0
    ) {
      financeiroValidations = financeiroValidation(
        formDataFormated,
        parcelasFinanceiro,
      );
      validations = [
        ...nfItensValidations,
        ...icmsValidations,
        ...financeiroValidations,
      ];
    } else {
      validations = [...nfItensValidations, ...icmsValidations];
    }
    setCriticas(validations);

    if (validations.length > 0) {
      setCurrentTab('critica');
      setLoading(false);
      toast.warning('Existem críticas a serem corrigidas.');
      return;
    }
    const formatedData = formatData(formData);

    // Chamada a API para inserir na tabela NF
    try {
      const { data } = await api.post('/nf-saida-devolucao', formatedData);

      if (data.success) {
        if (formData.cod_perfil.tipo_emissao === 1) {
          const { cod_seq_nf, num_nf } = data.data;

          await transmitirNFe({
            cod_loja: formatedData.cod_loja,
            cod_pessoa: formatedData.cod_pessoa,
            num_nf,
            tipo_nf: formatedData.tipo_nf,
            cod_seq_nf,
          });
        }

        resetFormData();
        setShowSearch(true);
        toast.success('NF Gravada com sucesso');
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
    }
  });

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={203}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={203}
          codigoRegistro={[
            { value: getValues('cod_seq_nf'), des_campo: 'Código' },
          ]}
          title="Devolução e Saída de NF's de Fornecedor"
          onSave={async () => {
            if (currentTab !== 'nfe') {
              setCurrentTab('nfe');
            }
            await onSave();
          }}
          isUpdate={isUpdate}
          onCancel={() => {
            resetFormData();
            setNfManutencao(false);
            setShowSearch(true);
          }}
          onNew={() => {
            resetFormData();
            setNfManutencao(false);
            setIsUpdate(false);
          }}
          isSave={isUpdate}
          isDelete={false}
          isDisabledSave={isUpdate}
          onDelete={() => false}
          onClearFields={() => {
            resetFormData();
            setNfManutencao(false);
          }}
          onReturnSearch={() => {
            resetFormData();
            setNfManutencao(false);

            setIsUpdate(false);
            setShowSearch(true);
          }}
          showSwitch={false}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {nfCancelada && (
                <h3
                  style={{
                    color: 'red',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  CANCELADA
                </h3>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {isUpdate && <TransmitirNFe codSeqNf={getValues('cod_seq_nf')} />}
              {nfManutencao && (
                <h3
                  style={{
                    color: 'green',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  MANUT. FISCAL
                </h3>
              )}
            </div>
          </div>
          <Tabs
            style={{ marginTop: '10px' }}
            id="controlled-produto"
            activeKey={currentTab}
            onSelect={(tab: any) => setCurrentTab(tab)}
          >
            <Tab eventKey="nfe" title="NF-e">
              <Nfe />
            </Tab>
            <Tab eventKey="produtor" title="Transporte/Obs">
              <Produtor />
            </Tab>
            <Tab eventKey="financeiro" title="Financeiro">
              <Financeiro />
            </Tab>
            <Tab
              eventKey="critica"
              title={
                <>
                  Críticas
                  {criticas.length > 0 && (
                    <Badge
                      variant="danger"
                      style={{
                        color: 'white',
                        backgroundColor: 'red',
                        marginLeft: '0.275rem',
                        borderRadius: '50%',
                      }}
                    >
                      {criticas.length}
                    </Badge>
                  )}
                </>
              }
            >
              <Critica />
            </Tab>
            {isUpdate && (
              <Tab eventKey="log" title="Log">
                <Log />
              </Tab>
            )}
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
